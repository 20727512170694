/**
 * Defines common component elements across pages 
 */
export const CommonMixin = {
    methods: {
        logout() {
            fetch('/logout', { method: 'POST' })
            .then(() => window.location = window.location.origin);
        }
    }
}